<template>
    <div class="mt-2">
        <div class="flex sm:items-baseline sm:space-x-4 mb-4">
            <router-link to="/dashboard/products/" class="text-sm font-medium text-purple-600 hover:text-purple-500 block cursor-pointer"><span aria-hidden="true"> &larr;</span> Back to product menu</router-link>
        </div>
        <div class="mb-4">
            <div class="mt-1 flex justify-between rounded-md">
                <div class="shadow-sm flex w-full">
                <span class="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm"> Name </span>
                <input type="text" v-model="newAttributeName" class="flex-1 min-w-0 block w-full px-3 py-2 rounded-none rounded-r-md focus:ring-purple-500 focus:border-purple-500 text-sm border-gray-300" placeholder="Enter name.." />
                </div>
                <button type="button" @click.prevent="addNewGroup"  :class="{'ml-4 inline-flex whitespace-nowrap items-center px-3 py-1.5 border border-transparent text-xs font-medium rounded-full shadow-sm text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500': true, 'opacity-40': newAttributeName.length === 0 }">Add new group</button>
            </div>         
        </div>
        <div>
            <h2 class="text-gray-500 text-xs font-medium uppercase tracking-wide">Available Groups of Attributes</h2>
            <ul role="list" class="mt-3 grid grid-cols-1 gap-5 sm:gap-6 sm:grid-cols-2 lg:grid-cols-4">
                <li v-for="attribute in groupsOfAttributes" :key="attribute.name" class="col-span-1 flex shadow-sm rounded-md">
                    <div :class="['bg-yellow-300', 'flex-shrink-0 flex items-center justify-center w-16 text-white text-sm font-medium rounded-l-md select-none']">
                        {{attribute.name.substring(0,3).toUpperCase()}}
                    </div>
                    <div class="flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 bg-white rounded-r-md truncate">
                        <div class="flex-1 px-4 py-2 text-sm truncate">
                            <router-link :to="`/dashboard/modifyAttribute?attrib=${attribute.id}`"  class="text-gray-900 font-medium hover:text-gray-600 select-none">{{ attribute.name }}</router-link>
                            <p class="text-gray-500 select-none">{{attribute.list.length}} values</p>
                        </div>
                        <div class="flex-shrink-0 pr-2">
                            <button type="button" class="w-8 h-8 bg-white inline-flex items-center justify-center text-gray-400 rounded-full bg-transparent hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">
                            <span class="sr-only">Open options</span>
                            <router-link  :to="`/dashboard/modifyAttribute?attrib=${attribute.id}`"><PencilAltIcon class="w-5 h-5" aria-hidden="true" /></router-link>
                            </button>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
    //import emptyState from '@/components/EmptyState.vue';
    //import axios from 'axios';
    import moment from 'moment';
    import {db} from "@/firebase/gfbconf.js";
    //import { Switch } from '@headlessui/vue'
    import { PencilAltIcon } from '@heroicons/vue/solid'

    export default {
        name: "Attributes",
        components: {PencilAltIcon},
        data()
        {
            return {
                enabledEdit: false,
                rerender: 0,
                deleteAsk: false,
                dbListener: null,
                newAttributeName: '',
                selected: [],
                modalData: {
                  headerText: "Delete group attribute",
                  bodyText: "Are you sure to delete selected attribute group? Data will be removed permanently.",
                  acceptButtonText: "Agree"
                },
                groupsOfAttributes: []
            }
        },
        created()
        {
            this.dbListener = db.collection("Systems").doc(this.$store.state.userData.system.id).collection("GroupsOfAttributes").orderBy("creationDate", "desc")
            .onSnapshot((querySnapshot) => {
                this.groupsOfAttributes = [];
                querySnapshot.forEach((doc) => {
                    this.groupsOfAttributes.push(doc.data());
                });
            });
        },
        methods:
        {
            async addNewGroup()
            {
                if(this.newAttributeName.length>0)
                {
                    const result = await db.collection("Systems").doc(this.$store.state.userData.system.id).collection("GroupsOfAttributes").add({
                        name: this.newAttributeName,
                        creationDate: moment().toDate(),
                        createdBy: this.$store.state.user.displayName,
                        type: "product",
                        list: [],
                        id: ""
                    });
                    this.$store.state.userData.system.ref.collection("Events").add(
                        {
                        user: {
                            name: this.$store.state.user.displayName,
                            photo: this.$store.state.user.photoURL
                        },
                        creationDate: moment().toDate(),
                        eventName: `Added new group of attributes: ${this.newAttributeName}.`
                    }).catch((error)=>
                    {
                    console.log(error);
                    });
                    await db.collection("Systems").doc(this.$store.state.userData.system.id).collection("GroupsOfAttributes").doc(result.id).update({
                        'id': result.id
                    });
                    this.newAttributeName = "";
                    this.$store.commit('setNotification',{
                        show: true,
                        head: "Group of attributes created with success!",
                        subheader: `You can now modify value options of group.`,
                        success: true
                    });
                }
            }
        },
        watch: {
        },
        computed:
        {
        },
        beforeUnmount() {
            this.dbListener();
        }
    }
</script>
<style lang="scss" scoped>
    .fade-enter-active,
    .fade-leave-active {
    transition: opacity 0.5s ease;
    }

    .fade-enter-from,
    .fade-leave-to {
    opacity: 0;
    }
</style>