<template>
    <div class="mt-2">
        <div class="flex sm:items-baseline sm:space-x-4 mb-4">
            <router-link to="/dashboard/products/" class="text-sm font-medium text-purple-600 hover:text-purple-500 block cursor-pointer"><span aria-hidden="true"> &larr;</span> Back to product menu</router-link>
        </div>
        <div class="mb-4">
            <div class="mt-1 flex rounded-md shadow-sm">
                <span class="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm"> Name </span>
                <input type="text" v-model="newmanufacturerName" class="flex-1 min-w-0 block w-full px-3 py-2 rounded-none rounded-r-md focus:ring-purple-500 focus:border-purple-500 text-sm border-gray-300" placeholder="Enter name.." />
                <button type="button" @click.prevent="addNewmanufacturer" :class="{'ml-4 inline-flex items-center px-3 py-1.5 border border-transparent text-xs font-medium rounded-full shadow-sm text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500': true, 'opacity-40': newmanufacturerName.length === 0 }">Add new</button>
            </div>         
        </div>
        <fieldset v-if="manufacturers.length>0" class="mt-2">
            <legend class="flex justify-between w-full text-lg font-medium text-gray-900">
                <div class="py-1.5">Available manufacturers</div>
            </legend>
            <div class="flex justify-between items-center mt-2 mb-2">
                <div class="flex items-center py-1.5">
                    <Switch v-model="enabledEdit" class="flex-shrink-0 group relative rounded-full inline-flex items-center justify-center h-5 w-10 cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">
                        <span class="sr-only">Use setting</span>
                        <span aria-hidden="true" class="pointer-events-none absolute bg-white w-full h-full rounded-md" />
                        <span aria-hidden="true" :class="[enabledEdit ? 'bg-purple-600' : 'bg-gray-200', 'pointer-events-none absolute h-4 w-9 mx-auto rounded-full transition-colors ease-in-out duration-200']" />
                        <span aria-hidden="true" :class="[enabledEdit ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none absolute left-0 inline-block h-5 w-5 border border-gray-200 rounded-full bg-white shadow transform ring-0 transition-transform ease-in-out duration-200']" />
                    </Switch>
                    <div class="text-sm text-gray-400 ml-2"> Modify </div>
                </div>
                <div v-show="this.selected.length>0">
                    <button @click.prevent="deleteAsk = true" type="button" class="inline-flex items-center rounded border border-red-400 bg-red-500 px-2.5 py-1.5 text-xs font-medium text-white shadow-sm hover:bg-red-400 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-30">Delete <span v-show="selected.length>1"> ({{selected.length}}) </span></button>
                </div>
            </div>
            <div class="mt-2 border-t border-b border-gray-200 divide-y divide-gray-200">
            <div v-for="(manufacturer, catIdx) in manufacturers" :key="catIdx" class="relative flex items-start py-4">
                <div class="flex items-center" v-show="enabledEdit">
                    <span class="text-xs mr-4 text-gray-400 w-14">ID: {{manufacturer.id}}</span>
                    <div class="mt-1 border-b border-gray-300 focus-within:border-purple-600" >
                        <input type="text" name="name" id="name" class="block w-full border-0 border-b border-transparent bg-gray-50 focus:border-purple-600 focus:ring-0 text-sm" placeholder="Enter new name.." :value="manufacturer.name" :ref="`updateInput-${manufacturer.id}`" />
                    </div>
                    <button type="button" @click.prevent="updateName(manufacturer.id,manufacturer.name)" :class="{'ml-4 inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-purple-700 bg-purple-100 hover:bg-purple-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500': true, 'opacity-40': false }">Update</button>                    
                </div>
                <div class="flex flex-1 text-sm" v-if="!enabledEdit">
                    <div class="w-14">
                        <span class="text-xs text-gray-400">ID:{{manufacturer.id}}</span>
                    </div>
                    <div>
                        <label :for="`manufacturer-${manufacturer.id}`" class="font-medium text-gray-700 select-none cursor-pointer"> {{ manufacturer.name }} </label>
                    </div>
                    
                </div>
                <div class="ml-3 flex items-center h-5" v-if="!enabledEdit">
                    <input :id="`manufacturer-${manufacturer.id}`" :key="rerender" @change="selectOption(manufacturer.id,manufacturer.name)" :name="`manufacturer-${manufacturer.id}`" type="checkbox" class="focus:ring-purple-500 h-4 w-4 text-purple-600 border-gray-300 rounded cursor-pointer" />
                </div>
            </div>
            </div>
        </fieldset>
        <emptyState v-show="manufacturers.length === 0"></emptyState>
        <askForDelete v-if="deleteAsk" :modalData="modalData" @accept="deleteFromDatabase" @closeModal="deleteAsk = false"></askForDelete>
    </div>
</template>

<script>
    import emptyState from '@/components/EmptyState.vue';
    //import axios from 'axios';
    import moment from 'moment';
    import {db} from "@/firebase/gfbconf.js";
    import { Switch } from '@headlessui/vue'
    import askForDelete from '@/components/AlertModal.vue';

    export default {
        name: "manufacturers",
        components: {emptyState, askForDelete, Switch},
        data()
        {
            return {
                enabledEdit: false,
                rerender: 0,
                deleteAsk: false,
                dbListener: null,
                newmanufacturerName: '',
                manufacturers: [],
                selected: [],
                modalData: {
                  headerText: "Delete manufacturer",
                  bodyText: "Are you sure to delete selected manufacturers? Data will be removed permanently.",
                  acceptButtonText: "Agree"
                }
            }
        },
        created()
        {
            this.dbListener = db.collection("Systems").doc(this.$store.state.userData.system.id).collection("Manufacturers").orderBy("creationDate", "desc")
            .onSnapshot((querySnapshot) => {
                this.manufacturers = [];
                querySnapshot.forEach((doc) => {
                    this.manufacturers.push(doc.data());
                });
            });
        },
        methods:
        {
            async updateName(id,currentName)
            {
                let value = this.$refs[`updateInput-${id}`][0].value;
                if(value.length>0)
                {
                    if(value !== currentName)
                    {
                        try{
                            await db.collection("Systems").doc(this.$store.state.userData.system.id).collection("Manufacturers").doc(id.toString()).update({
                                name: value
                            })
                            this.$store.commit('setNotification',{
                            show: true,
                            head: "Manufacturer updated!",
                            subheader: `Name has been changed with success.`,
                            success: true
                        });
                        this.$store.state.userData.system.ref.collection("Events").add(
                            {
                            user: {
                                name: this.$store.state.user.displayName,
                                photo: this.$store.state.user.photoURL
                            },
                            creationDate: moment().toDate(),
                            eventName: `Updated manufacturer name from ${currentName} to ${value}.`
                            }).catch((error)=>
                            {
                            console.log(error);
                            });

                        }
                        catch(error)
                        {
                            console.log(error);
                            this.$store.commit('setNotification',{
                                show: true,
                                head: "Manufacturer cannot be updated!",
                                subheader: `Something went wrong..`,
                                success: false
                            });   
                        }
                    }
                }
                else
                {
                    this.$store.commit('setNotification',{
                        show: true,
                        head: "Cannot update!",
                        subheader: `Value in field should not be empty.`,
                        success: false
                    });  
                }
            },
            async deleteFromDatabase()
            {
                for(let i=0;i<this.selected.length;i++)
                {
                    await db.collection("Systems").doc(this.$store.state.userData.system.id).collection("Manufacturers").doc(this.selected[i].toString()).delete();
                }

                this.$store.state.userData.system.ref.collection("Events").add(
                    {
                    user: {
                        name: this.$store.state.user.displayName,
                        photo: this.$store.state.user.photoURL
                    },
                    creationDate: moment().toDate(),
                    eventName: `Deleted ${this.selected.length} manufacturer(s) with id: ${this.selected}.`
                }).catch((error)=>
                {
                console.log(error);
                });
                this.selected = [];
                this.rerender++;
            },
            selectOption(id)
            {
                if(!this.selected.includes(id))
                {
                    this.selected.push(id);
                }
                else
                {
                    this.selected.splice(this.selected.indexOf(id),1);
                }
                
            },
            async calculateId()
            {
                const newId = Math.floor(Math.random() * 1000)+1;
                const freeId = await db.collection("Systems").doc(this.$store.state.userData.system.id).collection("Manufacturers").doc(newId.toString()).get();
                return {
                    id: newId,
                    exists: freeId.exists
                }
            },
            async addNewmanufacturer()
            {
                if(this.newmanufacturerName.length === 0)
                {
                    return 0;
                }

                try {
                    this.$store.commit('setGlobalLoader',{
                        show: true,
                        head: "Creating new manufacturer..",
                        subheader: `Do not close this tab.`,
                    });
                    let freeId = null;
                    for(let i=0; i < 999; i++)
                    {
                        const resultOfGettingId = await this.calculateId();
                        if(!resultOfGettingId.exists)
                        {
                            freeId = resultOfGettingId;
                            break;
                        }
                    }
                    if(freeId !== null)
                    {
                        await db.collection("Systems").doc(this.$store.state.userData.system.id).collection("Manufacturers").doc(freeId.id.toString()).set({
                            name: this.newmanufacturerName,
                            id: parseInt(freeId.id),
                            creationDate: moment().toDate(),
                            createdBy: this.$store.state.user.displayName,
                            products: []
                        });
                        this.$store.state.userData.system.ref.collection("Events").add(
                        {
                        user: {
                            name: this.$store.state.user.displayName,
                            photo: this.$store.state.user.photoURL
                        },
                        creationDate: moment().toDate(),
                        eventName: `Added new manufacturer: ${this.newmanufacturerName}.`
                        }).catch((error)=>
                        {
                        console.log(error);
                        });
                        this.$store.commit("resetGlobalLoader");
                        this.$store.commit('setNotification',{
                            show: true,
                            head: "Manufacturer created!",
                            subheader: `${this.newmanufacturerName} has been added.`,
                            success: true
                        });  
                        this.newmanufacturerName = "";
                        this.selected = []
                        this.rerender++;
                    }
                    else
                    {
                        this.$store.commit("resetGlobalLoader");
                        this.$store.commit('setNotification',{
                            show: true,
                            head: "Manufacturer cannot be created!",
                            subheader: `Could not find free id.`,
                            success: false
                        });                         
                    }
                }
                catch(error)
                {
                    this.$store.commit("resetGlobalLoader");
                    console.log(error);
                    this.$store.commit('setNotification',{
                        show: true,
                        head: "manufacturer cannot be created!",
                        subheader: `Something went wrong..`,
                        success: false
                    });   
                }
            }
        },
        watch: {
            enabledEdit()
            {
                this.selected = [];
            }
        },
        computed:
        {
        },
        beforeUnmount() {
            this.dbListener();
        }
    }
</script>
<style lang="scss" scoped>
    .fade-enter-active,
    .fade-leave-active {
    transition: opacity 0.5s ease;
    }

    .fade-enter-from,
    .fade-leave-to {
    opacity: 0;
    }
</style>